<template>
  <div class='page-box'>
    <base-screen class="custom-screen">
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
        <!-- <a-row> -->
        <a-form-model-item label="姓名" prop="realName">
          <a-input v-model="queryRef.realName" placeholder="请输入" />
        </a-form-model-item>

        <a-form-model-item label="联系方式" prop="phone">
          <a-input v-model="queryRef.phone" placeholder="请输入" />
        </a-form-model-item>

        <a-form-model-item label="审核状态" prop="state">
          <a-select v-model="queryRef.state" placeholder='请选择' style="width: 120px">
            <a-select-option v-for='item in withdrawState' :key='item.value' :value="item.value">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <!-- </a-row> -->
        <!-- <a-row> -->
        <a-form-model-item label="添加时间" prop="dateRange">
          <a-range-picker v-model="dateRange" />
        </a-form-model-item>
        <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(0)">
          今
        </a-button>
        <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(1)">
          昨
        </a-button>
        <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(7)">
          近7天
        </a-button>
        <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(30)">
          近30天
        </a-button>
        <!-- </a-row> -->
        <a-form-model-item class="screen-btns">
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <base-table :columns="columns" :dataSource="dataRef" :pagination="pageRtv" :rowKey="record => record.sharerWithdrawApplyId" :loading="loadingRef" @change="handleTableChange">
      <template #state="record">
        <span :style="{color: withdrawState.find(x=>x.value === record).color}">{{withdrawState.find(x=>x.value === record).name}}</span>
      </template>
      <template #operation="_,record">
        <div class='table-operations '>
          <a-button type='link' @click='passWithdraw(record)' :disabled='record.state !== 0 && record.state !==-1'>通过</a-button>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api'
import moment from 'moment'
import { marketing } from '@/api'
import { withdrawState } from '../../utils/constants'
const columns = [
  {
    title: '姓名',
    dataIndex: 'realName',
  },
  {
    title: '联系方式',
    dataIndex: 'phone',
  },
  {
    title: '提现金额',
    dataIndex: 'withdrawAmount',
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' },
  },
  {
    title: '申请时间',
    dataIndex: 'createTime',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
]

export default {
  setup (props, { root }) {
    const formRef = ref(null)
    const dateRange = ref([])
    watch(
      () => dateRange.value,
      (data) => {
        if (data.length) {
          queryRef.value.startTime = `${moment(dateRange.value[0]).format('YYYY-MM-DD')} 00:00:00`
          queryRef.value.endTime = `${moment(dateRange.value[1]).format('YYYY-MM-DD')} 23:59:59`
        } else {
          queryRef.value.startTime = ''
          queryRef.value.endTime = ''
        }
      }
    )

    const queryRef = ref({
      realName: '',
      phone: '',
      orderState: '',
      startTime: '',
      endTime: '',
      state: undefined,
    })
    const dataRef = ref([])
    const pageRtv = ref({
      current: 1,
      size: 10,
      total: 0,
    })
    const levels = ref([])
    const loadingRef = ref(false)
    onMounted(() => {
      Promise.all([getLevelData(), getTableData()])
    })
    async function getTableData () {
      loadingRef.value = true
      dataRef.value = []
      const { data, page } = await marketing.getWithdrawList({ ...queryRef.value, ...pageRtv.value })
      loadingRef.value = false

      dataRef.value = data || []
      pageRtv.value.total = page.total
    }

    function handleSearch () {
      pageRtv.value.current = 1
      getTableData()
    }

    async function handleReset () {
      formRef.value.resetFields()
      dateRange.value = []
      await root.$nextTick()
      handleSearch()
    }

    function handleTableChange (current) {
      pageRtv.value.current = current
      getTableData()
    }

    function handleDateShortcut (day) {
      dateRange.value = [
        moment().subtract(day, 'd'),
        day === 1 ? moment().subtract(1, 'd') : moment(),
      ]
    }
    async function getLevelData () {
      const { data, msg, code } = await marketing.getLevelData()
      if (code === '00000') {
        levels.value = data
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    async function passWithdraw (record) {
      root.$confirm({
        title: '操作提示',
        content: '确认通过此条提现申请？',
        onOk: async () => {
          const { code, msg } = await marketing.passWithdraw({
            sharerWithdrawApplyId: record.sharerWithdrawApplyId
          })
          if (code === '00000') {
            root.$message.success('操作成功')
            getTableData()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }

    return {
      queryRef,
      formRef,
      dateRange,
      handleDateShortcut,
      handleTableChange,
      handleReset,
      handleSearch,
      columns,
      levels,
      withdrawState,
      loadingRef,
      dataRef,
      pageRtv,
      passWithdraw
    }
  },
}
</script>

<style lang="less" scoped>
@label-width: 84px;
.custom-screen {
  ::v-deep .solt-div {
    padding: 10px 0 0;
  }
  ::v-deep .ant-form-item-label {
    width: @label-width;
  }
}
.screen-btns {
  margin-left: @label-width;
}
.btn-date-shortcut {
  margin: 4px 12px 0 0;
  &:last-child {
    margin-right: 0;
  }
}
.record-list {
  margin-bottom: 32px;
  .record-item {
    margin-bottom: 8px;
    &:last-child {
      margin: 0;
    }
  }
}
</style>
